// Common styles used by all icons in HTML and CSS
@mixin icon($fill: $black, $hover: $black) {
  display: block;
  fill: $fill;
  height: 35px;
  width: 35px;
  &:hover {
    fill: $hover;
  }
}

@mixin svg-icon-inline-mask($filename) {
  -webkit-mask: inline-image('icons/src/#{$filename}');
  mask: inline-image('icons/src/#{$filename}');
}
