/**
 * @file standalone/slide_panel_block.scss
 *
 * \brief Library file - shared styles for slide panel blocks
 *
 */

@import '../theme-bootstrap';

.slide_panel_block {
  &__slide-content {
    display: none;
  }
  &__reveal {
    display: none;
    &-backdrop {
      transition: opacity 0.3s;
      position: fixed;
      z-index: 10000;
      top: 0;
      #{$ldirection}: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      background: $color-bg-slide-grey-transparent;
    }
    &-content {
      background: $color-cream;
      position: fixed;
      z-index: 10001;
      transition: transform 0.5s, opacity 0.3s;
      transform: translateX(100%);
      opacity: 0;
      width: 100%;
      height: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      top: 0;
      #{$rdirection}: 0;
      @include breakpoint($landscape-up) {
        width: 78%;
        max-width: $slide-panel-max-width;
      }
      &-inner {
        padding: 0;
      }
    }
    &-close {
      display: block;
      width: 25px;
      height: 25px;
      border: none;
      -webkit-appearance: none;
      background: transparent;
      position: absolute;
      #{$rdirection}: 35px;
      top: 35px;
      z-index: 110;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .eh-content-formatter-v1 h3 {
    border-bottom-width: 3px;
  }
  .eh-carousel-v1.crash-carousel h3 {
    margin: 40px auto;
  }
  .eh-carousel-v1.crash-carousel {
    margin: 0;
  }
  .eh_hero_article_images_v2 {
    padding: 0 0 20px 0;
  }
  .flex-viewport .slides img {
    margin: auto;
    width: auto;
  }
  .btn-quickbuy {
    display: none;
  }
}

body.active-content-panel {
  overflow: hidden;
  .slide_panel_block {
    &__reveal {
      display: block;
    }
  }
}

html.slide_panel_enabled {
  overflow-y: visible;
  &.quiz-loaded {
    overflow: unset;
    overflow-y: visible;
  }
}

body.active-content-panel-animation {
  .slide_panel_block {
    &__reveal-backdrop {
      opacity: 1;
    }
    &__reveal-content {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

// PLP Panel Stylings.

.plp-panel {
  .slide_panel_block {
    &__reveal {
      @include breakpoint($landscape-up) {
        width: 633px;
      }
      &-content {
        @include breakpoint($landscape-up) {
          max-width: 520px;
        }
        &::-webkit-scrollbar {
          width: 5px;
          @include breakpoint($landscape-up) {
            width: 6px;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: $color-black;
          height: 50px;
        }
      }
      &-close {
        height: 54px;
        #{$rdirection}: 10px;
        top: 5px;
        width: 54px;
      }
      .sd-mounted {
        top: 85px;
        margin: 0 auto;
        @include breakpoint($landscape-up) {
          margin: 0;
        }
      }
      .elc-product-grid-header {
        display: block;
        font-family: $font--malone;
        font-size: 24px;
        padding: 0 20px;
        width: 100%;
      }
      .elc-product-brief-view {
        .elc-grid-item-product {
          margin-bottom: 25px;
          @include breakpoint($ipad-pro-portrait-only) {
            margin: 0 auto;
            width: 70%;
          }
          @include breakpoint($landscape-up) {
            margin-bottom: 15px;
          }
          .elc-product-brief {
            position: relative;
            height: 159px;
            @include breakpoint($landscape-up) {
              height: unset;
            }
            @include breakpoint($ipad-pro-portrait-only) {
              height: 200px;
            }
          }
        }
        .elc-main-content-section {
          display: inline-block;
          height: auto;
          width: 100%;
          @include breakpoint($landscape-up) {
            display: flex;
            height: 108px;
            width: 470px;
          }
        }
        .elc-product-image-badge-wrapper {
          display: inline-block;
          height: 112px;
          margin-#{$rdirection}: 21px;
          min-height: auto;
          width: 112px;
          &:hover {
            .elc-img {
              display: block;
            }
            .elc-img.elc-img--alt {
              display: none;
            }
          }
        }
        .elc-product-badge-wrapper {
          display: none;
        }
        .elc-product-display-name-wrapper {
          #{$ldirection}: 37%;
          display: inline-block;
          position: absolute;
          text-decoration: none;
          @include breakpoint($landscape-up) {
            #{$ldirection}: 124px;
          }
          .elc-product-display-name-link {
            font-size: 13px;
            font-family: $font--lars;
            letter-spacing: 0.3px;
            line-height: 2;
            text-align: $ldirection;
            width: 211px;
            @include breakpoint($landscape-up) {
              height: auto;
              line-height: 1.5;
              width: 150px;
            }
          }
        }
        .elc-size-picker-container {
          display: inline-block;
          #{$ldirection}: 37%;
          padding: 0;
          position: absolute;
          top: 71px;
          width: auto;
          @include breakpoint($landscape-up) {
            #{$ldirection}: 123px;
            top: 62px;
          }
          .elc-size-picker-wrapper {
            min-width: auto;
            width: 100%;
          }
        }
        .elc-price-and-size {
          font-family: $font--lars;
          font-size: 13px;
          width: auto;
          .elc-formatted-price {
            margin-#{$rdirection}: 16px;
          }
        }
        .elc-product-full-cta-wrapper {
          background: none;
          box-shadow: none;
          #{$ldirection}: 37%;
          padding: 0;
          position: absolute;
          top: 110px;
          width: auto;
          @include breakpoint($landscape-up) {
            display: inline-block;
            #{$ldirection}: 306px;
            top: 17px;
            width: 164px;
          }
          @include breakpoint($ipad-pro-portrait-only) {
            padding-top: 15px;
          }
          .elc-add-to-bag-button {
            border-radius: 0;
            color: inherit;
            font-family: $font--lars;
            height: 51px;
            text-transform: lowercase;
            width: 210px;
            &::first-letter {
              text-transform: capitalize;
            }
            @include breakpoint($landscape-up) {
              width: 164px;
            }
          }
          .elc-product-cta-wrapper {
            margin: 0;
          }
        }
      }
    }
  }
}

.active-content-panel-animation {
  .plp-panel {
    .slide_panel_block {
      &__reveal-backdrop {
        opacity: 0.4;
        z-index: 100000;
      }
      &__reveal-content {
        z-index: 100001;
      }
    }
  }
  &.gnav-util-overlay-active {
    .hide-gnav-overlay,
    .site-gnav-header-dark-overlay {
      // Used !important to override the inline style
      display: none !important; // stylelint-disable-line declaration-no-important
    }
  }
  .product-added {
    pointer-events: none;
    position: relative;
    text-indent: -999px;
    &::after {
      @include svg-icon-inline-mask('tick.svg');
      background-color: $color-black;
      content: '';
      height: 45px;
      #{$ldirection}: 50%;
      position: absolute;
      top: 2px;
      transform: translate(-50%);
      width: 45px;
    }
  }
}
